/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

body {
  width: 100vw;
  background: #e5e7eb;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-container {
  width: 100vw;
  height: -webkit-fill-available;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
    .slide-container {
        height: 100vh;
    }
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
  margin-right: env(safe-area-inset-right);
  margin-left: env(safe-area-inset-left);
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
}

.mute {
  top: 76px;
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
  margin-right: env(safe-area-inset-right);
  margin-left: env(safe-area-inset-left);
  right: 10px;
}

.play {
  top: 76px;
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
  margin-right: env(safe-area-inset-right);
  margin-left: env(safe-area-inset-left);
  right: 60px;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

ul {
  list-style: disc;
  margin-left: 1.25rem;
  padding-left: 2rem;
}

.bullet_level_2 > ul {
  padding-left: 3.75rem;
  list-style: circle;
}

li {
  padding: 0.5rem;
}

p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

input[type="radio"] + label span {
  transition: background .2s,
    transform .2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span{
transform: scale(1.2);
} 

input[type="radio"]:checked + label span {
background-color: #45afab;
box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + .wrong span {
background-color: #8f0303;
box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
 color: #45afab;
}

input[type="radio"]:checked + .wrong {
 color: #8f0303;
}

img {
  height: auto;
}

h2 {
  font-weight: 600;
}
